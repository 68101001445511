<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','status']" ref="list" :rowKey="'id'" >
            <template slot="left_btn">
                <a-button @click="getAddSave('','add')" type="primary" style="margin-left: 15px;">新增供应商</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.record.status == 1" class="status_box" @click="getMaterialStatus(data.record)">启用</span>
                <span v-else class="status_box" style="background:red;" @click="getMaterialStatus(data.record)">禁用</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="getPage(data.record)">查看</a>
                <a-divider type="vertical" />
                <a @click="getAddSave(data.record,'edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="getDelete(data.record)">删除</a>
            </template>
        </TableList>

        <!-- 添加或编辑供应商 -->
        <a-modal v-model:visible="supplierShow" :title="supplierFrom.id ? '编辑供应商信息' : '新增供应商信息'" @ok="supplierOk">
            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item required name='name' label="供应商名称" :rules="[{ required: true, message: '供应商名称不能为空',tagger:'blur' }]">
                    <a-input v-model:value="supplierFrom.supplier" placeholder="请输入供应商名称" />
                </a-form-item>
                <a-form-item required name='name' label="供应商编号" :rules="[{ required: true, message: '供应商编号不能为空',tagger:'blur' }]">
                    <a-input v-model:value="supplierFrom.supplier_code" placeholder="请输入供应商品编号" />
                </a-form-item>
                <a-form-item name='name' label="城市">
                    <a-cascader :options="areaData" v-model:value="defaultArea" placeholder="请选择城市" allow_clear />
                </a-form-item>
                <a-form-item name='name' label="邮政编码">
                    <a-input v-model:value="supplierFrom.postal_code" placeholder="请输入邮政编码" />
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
</template>
<script>
import Form from "@/components/Form"
import TableList from "@/components/TableList"
import {message,Modal} from 'ant-design-vue'
import {getSupplierList,saveSupplierInfo,delSupplierInfo,changeSupplierStatus} from '@/api/qms.js'
import {getArea} from "@/api/evection";
const columns = [{
        title: "供应商名称",
        dataIndex: "supplier",
    },
    {
        title: "供应商编码",
        dataIndex: "supplier_code",
    },
    {
        title: "联系人",
        dataIndex: "username",
    },
    {
        title: "联系人绑定数量",
        dataIndex: "bind_num",
    },
    {
        title: "城市",
        dataIndex: "city",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default {
    name: "Index",
    components: {
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: getSupplierList,
            columns,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "请输入供应商名称",
                        options: {}
                    },
                ],
            },
            supplierShow:false,
            supplierFrom:{
                id:'',
                supplier:'',
                supplier_code:'',
                city:'',
                postal_code:'',
            },

            labelColEdit: {
                span: 6
            },
            wrapperColEdit: {
                span: 16
            },

            areaData:[],
            defaultArea:[],
        }
    },
    created() {
        this.get_getArea()
    },
    methods: {
        get_getArea() {
            getArea().then(res => {
                this.areaData = res.data.list
            })
        },

        getAddSave(info,type){
            //新增
            if(type == 'add'){
                this.supplierFrom = {}
                this.defaultArea = []
                this.supplierShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.defaultArea = []
                this.supplierFrom.id = info.id
                this.supplierFrom.supplier = info.supplier
                this.supplierFrom.supplier_code = info.supplier_code
                this.supplierFrom.postal_code = info.postal_code
                if(info.city){
                    let cityData = info.city.split('/')
                    this.defaultArea = [cityData[0],cityData[1]]
                }
                this.supplierShow = true
            }
        },

        supplierOk(){
            if(this.defaultArea){
                this.supplierFrom.city = this.defaultArea.join('/')
            }
            saveSupplierInfo({
                ...this.supplierFrom
            }).then(res=>{
                this.supplierShow = false
                message.success('保存成功');
                this.$refs.list.get_list()
            })
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除供应商【'+info.supplier+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delSupplierInfo({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getMaterialStatus(info){
            let text = info.status == 1 ? "禁用" : "启用"
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要'+text+'供应商【'+info.supplier+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    changeSupplierStatus({
                        id:info.id
                    }).then(res=>{
                        message.success('操作成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getPage(info){
            this.$router.push({path: '/qmsSupplier/details',query: {id: info.id}})
        },
    }
}
</script>

<style lang="less" scoped>
.status_box{
    background:#1890ff;
    padding:1px 5px 3px 5px;
    color:#ffffff;
    border-radius:2px;
    font-size:12px;
    cursor:pointer;
    display:inline-block;
    min-width:34px;
}
</style>
